import { RootState } from '../../redax/reducers/rootReducer';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecruitmentsRequest } from "../../redax/actions/recruitmentsActions/recruitmentsActions";
import { fetchRegionsRequest } from "../../redax/actions/regionsActions/regionsActions";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Column from '../../components/column/column';
import Column01 from '../../components/column01/column01';
import { fetchLoginRequest } from '../../redax/actions/loginActions/loginActions';
import { fetchRecruitmentsJobsRequest } from '../../redax/actions/recruitmentsJobsActions/recruitmentsJobsActions';
import { BrowserView, MobileView, isBrowser, isDesktop, isMobile } from 'react-device-detect';

function Indexs() {
    const dispatch = useDispatch();
    const { recruitments , pending} = useSelector(
      (state: RootState) => state.recruitments
    );
    const { regions  } = useSelector(
        (state: RootState) => state.regions
    );

    const recruitmentsJobs = useSelector(
        (state: RootState) => state.recruitermentsJobs
      );
    
    const loginData = useSelector((state: RootState) => state.login);

    const [ prefectureId , setprefectures] = useState('');
    const [selectJobCategoryTtypes , setjob_category_types] = useState('');
    const [selectEmploymentStatuses , setemployment_statuses] = useState('');
    const [searchDisabled, setSearchDisabled] = useState(true);
    const [isLogin, setLogin] = useState(false);
    const navigate = useNavigate();
    const pageTitle = 'パラちゃんねる';
  document.title = pageTitle;
    const backColor = {
        background: 'gray'
      };

    const prefecturesChange = (event:any) => {
        setprefectures(event.target.value);
    }

    const categorySelectChange = (event:any) => {
        setjob_category_types(event.target.value);
    }

    const employeeSelectChange = (event:any) => {
    setemployment_statuses(event.target.value);
    }

    const [hokkaido , setHokkaido] = useState(null);
    const [northeast , setNortheast] = useState(null);
    const [kanto , setKanto] = useState(null);
    const [central , setCentral] = useState(null);
    const [kinki , setKinki] = useState(null);
    const [china , setChina] = useState(null);
    const [kyushu , setKyushu] = useState(null);
    const [shikoku , setShikoku] = useState(null);
    const [region_id_1_2_list , setRegion_id_1_2_list] = useState<any>([]);
    const [region_id_3_list , setRegion_id_3_list] = useState<any>([]);
    const [region_id_4_list , setRegion_id_4_list] = useState<any>([]);
    const [region_id_6_7_list , setRegion_id_6_7_list] = useState<any>([]);
    const [region_id_8_list , setRegion_id_8_list] = useState<any>([]);
    const [region_id_5_list , setRegion_id_5_list] = useState<any>([]);
    const [region1_2_list , setRegion1_2_list] = useState<any>([]);
    const [precfecture1_2_list , setPrecfecture1_2_list] = useState<any>([]);
    const [region3_list , setRegion3_list] = useState<any>([]);
    const [precfecture3_list , setPrecfecture3_list] = useState<any>([]);
    const [region4_list , setRegion4_list] = useState<any>([]);
    const [precfecture4_list , setPrecfecture4_list] = useState<any>([]);
    const [region5_list , setRegion5_list] = useState<any>([]);
    const [precfecture5_list , setPrecfecture5_list] = useState<any>([]);
    const [region6_7_list , setRegion6_7_list] = useState<any>([]);
    const [precfecture6_7_list , setPrecfecture6_7_list] = useState<any>([]);
    const [region8_list , setRegion8_list] = useState<any>([]);
    const [precfecture8_list , setPrecfecture8_list] = useState<any>([]);
    const [prefecturesArr , setPrecfectureArr] = useState<any>([]);
    const [job_category_typesArr , setJobCategoryTypesArr] = useState<any>([]);
    const [employment_statusesArr , setEmploymentStatusesArr] = useState<any>([]);
    
    
    useEffect(() => {
        if(localStorage.getItem('jobParachannelAuthToken')){
            dispatch(fetchLoginRequest('', '',''));
            if(loginData !== undefined){
              if(loginData.pending){
                setLogin(true);
              }
            }
        }
      }, [dispatch, localStorage.getItem('jobParachannelAuthToken')]);

    useEffect(() => {
        dispatch(fetchRecruitmentsRequest());
        dispatch(fetchRecruitmentsJobsRequest());
        dispatch(fetchRegionsRequest());
    }, [dispatch]);

      useEffect(() => {
        if(prefectureId !== '' || selectJobCategoryTtypes !== '' ||selectEmploymentStatuses !== '' ){
            setSearchDisabled(false);
        }
      },[prefectureId,selectJobCategoryTtypes,selectEmploymentStatuses])

    let prefectures: string | any[] = [];
    let search_regions: string | any[] = [];
    let employment_statuses: any[] = [];
    let job_category_types: any[] = [];
    let recruitments1 = [];
    let recruitments2 = [];
    let recruitments3 = [];
    let recruitments4 = [];
    let recruitments5 = [];
    let recruitments6 = [];
    let recruitmentsPickUp = [];
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('consideration_conditions', 'true');

    useEffect(() =>{ 
        let list_1 = [];
        let list_2 = [];
        let list_3 = [];
        let list_4 = [];
        let list_5 = [];
        let list_6 = [];
        let reg1_2 = [];
        let reg6_7 = [];
        let pre1_7 = [];
        let pre8_14 = [];
        let pre15_23 = [];
        let pre24_30 = [];
        let pre31_39 = [];
        let pre40_47 = [];
        if(regions.prefectures !== undefined){
            prefectures = regions.prefectures
            setPrecfectureArr(regions.prefectures);
        }

        if(regions.job_category_types !== undefined){
            job_category_types = regions.job_category_types;
            setJobCategoryTypesArr(regions.job_category_types);
        }

        if(regions.employment_statuses !== undefined){
            employment_statuses = regions.employment_statuses;
            setEmploymentStatusesArr(regions.employment_statuses);
        }

        if(regions.search_regions !== undefined){
            search_regions = regions.search_regions;
            setHokkaido(search_regions[0].name);
            setNortheast(search_regions[1].name);
            setKanto(search_regions[2].name);
            setCentral(search_regions[3].name);
            setKinki(search_regions[4].name);
            setChina(search_regions[5].name);
            setShikoku(search_regions[6].name);
            setKyushu(search_regions[7].name);
        }
        if(prefectures.length > 0 && search_regions.length > 0) {
            reg1_2.push('region_ids[]=' + search_regions[0].id);
            reg1_2.push('region_ids[]=' + search_regions[1].id);          
            const query1 = reg1_2.join('&');
            setRegion1_2_list(query1);
            setRegion3_list('region_ids[]=' + search_regions[2].id);
            setRegion4_list('region_ids[]=' + search_regions[3].id);
            setRegion5_list('region_ids[]=' + search_regions[4].id);
            reg6_7.push('region_ids[]=' + search_regions[5].id);
            reg6_7.push('region_ids[]=' + search_regions[6].id);         
            const query2 = reg6_7.join('&');
            setRegion6_7_list(query2);
            setRegion8_list('region_ids[]=' + search_regions[7].id);
            for(let i = 0; i < prefectures.length; i++) {
                if(prefectures[i].region_id == search_regions[0].id){
                    // region_id_1_2_list.push(prefectures[i]);
                    list_1.push(prefectures[i]);
                    pre1_7.push('prefecture_ids[]=' + prefectures[i].id);
                    // setRegion1_precfecture(prefectures[i].id);
                } else if(prefectures[i].region_id == search_regions[1].id){
                    // region_id_1_2_list.push(prefectures[i]);
                    list_1.push(prefectures[i]);
                    pre1_7.push('prefecture_ids[]=' + prefectures[i].id);
                    const query = pre1_7.join('&');
                    setPrecfecture1_2_list(query);
                } else if(prefectures[i].region_id == search_regions[2].id){
                    // region_id_3_list.push(prefectures[i]);
                    list_2.push(prefectures[i]);
                    pre8_14.push('prefecture_ids[]=' + prefectures[i].id);
                    const query = pre8_14.join('&');
                    setPrecfecture3_list(query);
                } else if(prefectures[i].region_id == search_regions[3].id){
                    // region_id_4_list.push(prefectures[i]);
                    list_3.push(prefectures[i]);
                    pre15_23.push('prefecture_ids[]=' + prefectures[i].id);
                    const query = pre15_23.join('&');
                    setPrecfecture4_list(query);
                } else if(prefectures[i].region_id == search_regions[4].id){
                    // region_id_5_list.push(prefectures[i]);
                    list_4.push(prefectures[i]);
                    pre24_30.push('prefecture_ids[]=' + prefectures[i].id);
                    const query = pre24_30.join('&');
                    setPrecfecture5_list(query);
                } else if(prefectures[i].region_id == search_regions[5].id){
                    // region_id_6_7_list.push(prefectures[i]);
                    list_5.push(prefectures[i]);
                    pre31_39.push('prefecture_ids[]=' + prefectures[i].id);
                } else if(prefectures[i].region_id == search_regions[6].id){
                    // region_id_6_7_list.push(prefectures[i]);
                    list_5.push(prefectures[i]);
                    pre31_39.push('prefecture_ids[]=' + prefectures[i].id);
                    const query = pre31_39.join('&');
                    setPrecfecture6_7_list(query);
                } else if(prefectures[i].region_id == search_regions[7].id){
                    // region_id_8_list.push(prefectures[i]);
                    list_6.push(prefectures[i]);
                    pre40_47.push('prefecture_ids[]=' + prefectures[i].id);
                    const query = pre40_47.join('&');
                    setPrecfecture8_list(query);
                } 
            }
            if(list_1.length != 0) {
                setRegion_id_1_2_list(list_1);
            }
            if(list_2.length != 0) {
                setRegion_id_3_list(list_2);
            }
            if(list_3.length != 0) {
                setRegion_id_4_list(list_3);
            }
            if(list_4.length != 0) {
                setRegion_id_5_list(list_4);
            }
            if(list_5.length != 0) {
                setRegion_id_6_7_list(list_5);
            }
            if(list_6.length != 0) {
                setRegion_id_8_list(list_6);
            }
        }
    },[regions]);


    if(recruitments.recruitments !== undefined) {
        recruitmentsPickUp = recruitments.recruitments[5];
        recruitments1 = recruitments.recruitments[0];
        recruitments2 = recruitments.recruitments[1];
        recruitments3 = recruitments.recruitments[2];
        recruitments4 = recruitments.recruitments[3];
        recruitments5 = recruitments.recruitments[4];
    }

    if(recruitmentsJobs.recruitments !== undefined && recruitmentsJobs.recruitments.recruitments !== undefined){
        recruitments6 = recruitmentsJobs.recruitments.recruitments.slice(0, 12);
    }




    const jobSearch = () => {
        if (prefectureId || selectJobCategoryTtypes || selectEmploymentStatuses) {
          let query = '';
          const arr = [];
    
          if (prefectureId !== '') {
            arr.push('prefecture_ids[]=' + prefectureId);
          }
          if (selectJobCategoryTtypes !== '') {
            arr.push('job_category_type_ids[]=' + selectJobCategoryTtypes);
          }
          if (selectEmploymentStatuses !== '') {
            arr.push('employment_status_ids[]=' + selectEmploymentStatuses);
          }
          
          query = arr.join('&');
          window.location.href = ('/recruitments/?page=1&' + query);
        }
      };

    const replacedContent = (value: any) => {
        if (value == null) {
            return null
        }
        return value.replaceAll("\n", '</br>');
    }

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

    
      useEffect(() => {
        // Attach the event listener on component mount
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };

      }, [windowSize]);
      
    const clickedTypeA = () => {
        // navigate('/recruitments/?page=1&employment_status_ids[]=1&employment_status_ids[]=2', {state: {keyword: "A型"}});
        navigate('/recruitments/?page=1', {state: {keyword: "A型"}});
    }

    const clickedJobCategoryModal = () => { 
        navigate('/recruitments/?page=1', {state: {jobCategoryModal: true}});
    }

    const clickedConsiderationConditionsModal = () => {
        navigate('/recruitments/?page=1', {state: {considerationConditionsModal: true}});    
    }

    const clickedRecruitment = () => {
        navigate('/recruitments/?page=1', {state: {work_from_home_id:[{work_from_home_id: 1}, {work_from_home_id: 3}]}});
    }

    const clickedRecruitmentNewPublish = () => {
        navigate('/recruitments', {state: {is_new_publish: true}});
    }

    return (
        <main id="top">
            <section className="mv">
                {/* <div className="list_box">
                    <p>どこで働きたい？</p>
                    <div>
                        <ul>
                            <li><a href="/recruitments/">全国</a></li>
                            {search_regions.map((region: any, index: any) => (
                                <li key={index}><a href={'/recruitments?region_ids[]='+ region.id + '&'+ region.search}>{region.name}</a></li>
                            ))}
                        </ul>
                    </div>
                </div> */}
                {/* <div className="cont_box">
                    <div className="wrapper">
                    <form>
                        <h2>お仕事探しをはじめよう</h2>
                        <dl>
                        <dt><img src="assets/img/top/article_icon06.svg" alt=""/>勤務地</dt>
                        <dd>
                            <select name="prefectures" value = {prefectureId} onChange={prefecturesChange}>
                            <option value = ""  disabled>選択してください</option>
                            {prefectures.map((option: any, index: any) => (
                                <option key={index} value={option.id}>
                                {option.name}
                                </option>
                            ))}
                            </select>
                        </dd>
                        <dt><img src="assets/img/top/article_icon07.svg" alt=""/>職種</dt>
                        <dd>
                            <select name="job_category_types"  value = {selectJobCategoryTtypes} onChange={categorySelectChange} >
                                <option value=""  disabled>選択してください</option>
                                {job_category_types.map((option: any, index: any) => (
                                    <option key={index} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                            </select>
                        </dd>
                        <dt><img src="assets/img/top/article_icon08.svg" alt=""/>雇用形態</dt>
                        <dd>
                            <select name="employment_status_id"  value = {selectEmploymentStatuses} onChange={employeeSelectChange} >
                                <option value=""  disabled>選択してください</option>
                                {employment_statuses.map((option: any, index: any) => (
                                    <option key={index} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                            </select>
                        </dd>
                        </dl>
                        <button type="button"  className={`btn type3 ${searchDisabled ? 'disabled' : ''}`} disabled={searchDisabled}  id="searchButton" onClick={jobSearch}  >絞り込み検索</button>
                    </form>
                    </div>
                </div> */}
                {windowSize.width <= 768 ?
                 <>
                    <div className="cont_box">
                        <div className="wrapper">
                        <form>
                            <h2>お仕事探しをはじめよう</h2>
                            <dl className='marginAuto'>
                            <dt><img src="assets/img/top/article_icon06.svg" alt=""/>勤務地</dt>
                            <dd>
                                <select name="prefectures" value = {prefectureId} onChange={prefecturesChange}>
                                <option value = ""  disabled>選択してください</option>
                                {prefecturesArr.map((option: any, index: any) => (
                                    <option key={index} value={option.id}>
                                    {option.name}
                                    </option>
                                ))}
                                </select>
                            </dd>
                            <dt><img src="assets/img/top/article_icon07.svg" alt=""/>職種</dt>
                            <dd>
                                <select name="job_category_types"  value = {selectJobCategoryTtypes} onChange={categorySelectChange} >
                                    <option value=""  disabled>選択してください</option>
                                    {job_category_typesArr.map((option: any, index: any) => (
                                        <option key={index} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                </select>
                            </dd>
                            <dt><img src="assets/img/top/article_icon08.svg" alt=""/>雇用形態</dt>
                            <dd>
                                <select name="employment_status_id"  value = {selectEmploymentStatuses} onChange={employeeSelectChange} >
                                    <option value=""  disabled>選択してください</option>
                                    {employment_statusesArr.map((option: any, index: any) => (
                                        <option key={index} value={option.id}>
                                        {option.name}
                                        </option>
                                    ))}
                                </select>
                            </dd>
                            </dl>
                            <button type="button" style={{marginTop:'15px'}} className={`btn type3 ${searchDisabled ? 'disabled' : ''}`} disabled={searchDisabled}  id="searchButton" onClick={jobSearch}  >絞り込み検索</button>
                        </form>
                        </div>
                    </div>
                </>
                 : 
                <div className="cont_box">
                    <div className="wrapper">
                    <div className='rowLine'>
                        <div  style={{height: "100px", width: "100%", padding: "25px 0", marginRight:'10px'}}>
                            <button onClick={clickedRecruitment} className='recruitmentBtn'>リモート求人を探す</button>
                        </div>  
                        <div   style={{height: "100px", width: "100%", padding: "25px 0",marginLeft:'10px'}}>
                            <button onClick={clickedRecruitmentNewPublish} className='recruitmentBtn' >新着求人を探す</button>
                        </div>         
                    </div>
                    <form style={{width: "100%", margin: "0 auto", borderRadius: "0"}}>
                        <ul className="tab_btn formFontTitle">
                            <li><img src='assets/img/top/location-marker.svg' alt=""/><a className='colorBlue'>勤務地で探す</a></li>
                            <li><img style={{color: "#000"}} src='assets/img/top/briefcase.svg' alt=""/><a className='colorBlack' onClick={clickedJobCategoryModal}>職種で探す</a></li>
                            <li><img src='assets/img/top/雇用形態.svg' alt=""/><a className='colorBlack' onClick={clickedConsiderationConditionsModal}>雇用形態で探す</a></li>
                            <li><img src='assets/img/top/A型.svg' alt=""/><a className='colorBlack' onClick={clickedTypeA}>A型事業所を探す</a></li>
                        </ul>
                        <div className='rowHead'>
                            <div className='columnHead'>
                                {/* 北海道 : 東北 */}
                                <a className='top__region_a_tag' href={'/recruitments?' + precfecture1_2_list}> <span className='borderBottom'>{hokkaido} : {northeast}</span> </a>
                                
                                <br />
                                <div className="prefectureInfo">
                                    { 
                                        region_id_1_2_list && region_id_1_2_list.length != 0 ?
                                        <div className='d-flex'>
                                            {region_id_1_2_list && region_id_1_2_list.map((region: any) => (
                                                // <button   onClick={() => navigate('/recruitments?region_ids[]='+ region.region_id + '&prefecture_ids[]=' + region.id)}>{region.name.replace("県", "")}</button>
                                                <button   onClick={() => navigate('/recruitments?prefecture_ids[]=' + region.id)}>{region.name.replace("県", "")}</button>

                                            ))} 
                                        </div>
                                        : 
                                        <>
                                        </>
                                    }
                                </div>
                                <br/>
                                {/* 中部 : 北陸*/}
                                {/* <p style={{fontWeight: "bold", fontSize: "16px"}}>{central} : 北陸</p>      */}
                                {/* <a className='top__region_a_tag' href={'/recruitments?'+ region4_list + '&' + precfecture4_list}> <span className='borderBottom'>{central} : 北陸</span></a>  */}
                                <a className='top__region_a_tag' href={'/recruitments?' + precfecture4_list}> <span className='borderBottom'>{central} : 北陸</span></a> 

                                <br />
                                <div className="prefectureInfo">
                                    { 
                                        region_id_4_list && region_id_4_list.length != 0 ?
                                        <div className='d-flex'>
                                            {region_id_4_list && region_id_4_list.map((region: any) => (
                                                // <button  onClick={ ()=> navigate('/recruitments?region_ids[]='+ region.region_id + '&prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "")}</button>
                                                <button  onClick={ ()=> navigate('/recruitments?prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "")}</button>

                                            ))} 
                                        </div>
                                        : 
                                        <>
                                        </>
                                    }
                                </div>  
                                <br/>
                                {/* 中国 : 四国 */}
                                {/* <a className='top__region_a_tag' href={'/recruitments?'+ region6_7_list + '&' + precfecture6_7_list}> <span className='borderBottom'>{china} : {shikoku}</span> </a>  */}
                                <a className='top__region_a_tag' href={'/recruitments?'+ precfecture6_7_list}> <span className='borderBottom'>{china} : {shikoku}</span> </a> 

                                <br />
                                <div className="prefectureInfo">
                                    { 
                                        region_id_6_7_list && region_id_6_7_list.length != 0 ?
                                        <div className='d-flex'>
                                            {region_id_6_7_list && region_id_6_7_list.map((region: any) => (
                                                // <button onClick={ ()=> navigate('/recruitments?region_ids[]='+ region.region_id + '&prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "")}</button>
                                                <button onClick={ ()=> navigate('/recruitments?prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "")}</button>

                                            ))} 
                                        </div>
                                        : 
                                        <>
                                        </>
                                    }
                                </div>
                            </div>
                            
                            <div className='columnHead'>
                                {/* 関東 */}
                                {/* <a className='top__region_a_tag' href={'/recruitments?'+ region3_list + '&' + precfecture3_list}> <span className='borderBottom'> {kanto}</span> </a>  */}
                                <a className='top__region_a_tag' href={'/recruitments?' + precfecture3_list}> <span className='borderBottom'> {kanto}</span> </a> 

                                <br />
                                <div className="prefectureInfo">
                                    {
                                        region_id_3_list && region_id_3_list.length != 0 ?
                                        <div className='d-flex'>
                                            {region_id_3_list && region_id_3_list.map((region: any) => (
                                                // <button onClick={ ()=> navigate('/recruitments?region_ids[]='+ region.region_id + '&prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "")}</button>
                                                <button onClick={ ()=> navigate('/recruitments?prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "")}</button>

                                            ))} 
                                        </div>
                                        : 
                                        <>
                                        </>
                                    }
                                </div>
                                <br/>
                                {/* 関西 */}
                                {/* <a className='top__region_a_tag' href={'/recruitments?'+ region5_list + '&' + precfecture5_list}> <span className='borderBottom'>関西</span> </a>  */}
                                <a className='top__region_a_tag' href={'/recruitments?' + precfecture5_list}> <span className='borderBottom'>関西</span> </a> 

                                <br />
                                <div className="prefectureInfo">
                                    {
                                        region_id_5_list && region_id_5_list.length != 0 ?
                                        <div className='d-flex'>
                                            {region_id_5_list && region_id_5_list.map((region: any) => (
                                                // <button  onClick={ ()=> navigate('/recruitments?region_ids[]='+ region.region_id + '&prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "").replace('府', '')}</button>
                                                <button  onClick={ ()=> navigate('/recruitments?prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "").replace('府', '')}</button>

                                            ))} 
                                        </div>
                                        : 
                                        <>
                                            <></>
                                        </>
                                    }
                                </div>
                                <br/>
                                {/* 九州 : 沖縄 */}
                                {/* <a className='top__region_a_tag' href={'/recruitments?'+ region8_list + '&' + precfecture8_list}> <span className='borderBottom'>{kyushu} : 沖縄</span> </a>  */}
                                <a className='top__region_a_tag' href={'/recruitments?'+ precfecture8_list}> <span className='borderBottom'>{kyushu} : 沖縄</span> </a> 
                                <br />
                                <div className="prefectureInfo">
                                    {
                                        region_id_8_list && region_id_8_list.length != 0 ?
                                        <div className='d-flex'>
                                            {region_id_8_list && region_id_8_list.map((region: any) => (
                                                // <button  onClick={ ()=> navigate('/recruitments?region_ids[]='+ region.region_id + '&prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "")}</button>
                                                <button  onClick={ ()=> navigate('/recruitments?prefecture_ids[]=' +  region.id)}>{region.name.replace("県", "")}</button>

                                            ))} 
                                        </div>
                                        : 
                                        <>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                    
                    </div>
                </div>
                }
                
                {/* <div style={{height: "100px", width: "100%"}}>
                    <p style={{height: "100px", width: "100%", textAlign: "center", color: "#00a0e9", background: "#f3e122", fontWeight: "bold", fontSize: "22px", padding: "40px"}}></p>
                    <p style={{height: "100px", width: "100%", textAlign: "center", color: "#00a0e9", background: "#f3e122", fontWeight: "bold", fontSize: "22px", padding: "40px"}}>登録企業数0社 /  求人数0件</p>
                </div> */}
            </section>
            {windowSize.width <= 768 ?
            <section className='wrapper'>
                <div className='buttonCont'>
                    <button className='searchButtun' onClick={clickedTypeA}>A型事業所の求人を検索</button>
                    <div className='dflex'>
                        <button style={{marginRight: '10px'}} onClick={clickedRecruitment}>リモート求人を探す</button>
                        <button onClick={clickedRecruitmentNewPublish}>新着求人を探す</button>
                    </div>
                </div>
            </section>
             : <></>}
            <section className="article wrapper">
            {windowSize.width <= 768 ? 
                <>
                    <div className="sm_jobs_wrapper">
                        <h2 className="ttl type1">
                            <img src="./assets/img/top/premium.svg" alt=""></img>
                            <span>プレミアム求人</span>
                        </h2>
                        {recruitments6.length !== 0 ? 
                        <>
                            {recruitments6.slice(0,3).map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li className="premium_jobs_wrapper">
                                        <a href={'/recruitments/'+ data.id }>
                                            <div className ="img_box relat">
                                                <div className="ribbon19-content">
                                                <span className="ribbon19">PREMIUM</span>
                                                </div>
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl_jobs2">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p> {replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                            {data.view_count ? 
                                            <div className='smallheart'>
                                                <img src="/assets/img/smallHeart.svg" alt="" /> 
                                                <p>{data.view_count}</p>
                                            </div> : <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </> :
                        <div className="load-spinner">
                            <span className="spinner"></span>
                        </div>
                        }
                        <a href="/jobs/recommended">すべて見る</a>
                    </div>
                    <div className="sm_jobs_wrapper">
                        <h2 className="ttl type1">
                            <img src="./assets/img/top/pickup.svg" alt=""></img>
                            <span>ピックアップ求人</span>
                        </h2>
                        {recruitmentsPickUp.length !== 0 ? <>
                            {recruitmentsPickUp.slice(0,6).map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li className="card_job_wrapper">
                                        <a href={'/recruitments/'+ data.id }>
                                            <h3 className = "title">{data.job_title}</h3>
                                            <p className="text">{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <div className="tag_wrapper">
                                                <span className = "tag_text">{data.employment_status}</span>
                                                <span className = "tag_text">{data.new_graduate}</span>
                                            </div>
                                            <p className="text"> {replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            <p className="sub_text">{data.company_name}</p>
                                            {data.already_applied ? <span className="done" >エントリー済み</span>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </>:
                        <div className="load-spinner">
                            <span className="spinner"></span>
                        </div>}
                        <a href="/recruitments/?page=1&employment_status_ids[]=1&employment_status_ids[]=2">すべて見る</a>
                    </div>
                    <div className="sm_jobs_wrapper">
                    <h2 className ="ttl type1">
                        <img src="/assets/img/top/physical_disability.svg" alt=""></img>
                        <span>身体的</span>なことへの配慮がある仕事
                    </h2>
                    {recruitments1.length !== 0 ? 
                            <>
                                {recruitments1.slice(0,3).map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                            </>
                        : 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=1">すべて見る</a>
                </div>
                <div className="sm_jobs_wrapper">
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/heart.svg" alt=""></img>
                        <span>精神的</span>なことへの配慮がある仕事
                    </h2>
                    {recruitments2.length !== 0 ? 
                            <>
                                {recruitments2.slice(0,3).map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=2">すべて見る</a>
                </div>
                <div className="sm_jobs_wrapper">
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/star.svg" alt=""></img>
                        <span>発達特性</span>への配慮がある仕事
                    </h2>
                    {recruitments3.length !== 0 ? 
                        <>
                                {recruitments3.slice(0,3).map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=3">すべて見る</a>
                </div>
                <div className="sm_jobs_wrapper">
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/rainbow.svg" alt=""></img>
                        <span>知的障がい</span>への配慮がある仕事
                    </h2>
                    {recruitments4.length !== 0 ? 
                        <>
                                {recruitments4.slice(0,3).map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=4">すべて見る</a>
                </div>
                <div className="sm_jobs_wrapper">
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/pin.svg" alt=""></img>
                        <span>難病・その他特性</span>への配慮がある仕事
                    </h2>
                    {recruitments5.length !== 0 ? 
                        <>
                                {recruitments5.slice(0,3).map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id }>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=5">すべて見る</a>
                </div>

                </>
            : 
                <>
                <div>
                    <h2 className="ttl type1">
                        <img src="./assets/img/top/premium.svg" alt=""></img>
                        <span>プレミアム求人</span>
                    </h2>
                    {recruitments6.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 3}
                            keyboard={{
                                enabled: true
                            }}
                            loop
                            autoHeight={false}
                            >
                                {recruitments6.map((data: any) => {
                                if (data.lower_monthly_basic_salary !== undefined && data.upper_monthly_basic_salary !== undefined) {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li className="premium_jobs_wrapper ribbon19-wrapper">
                                        <a href={'/recruitments/'+ data.id }>
                                            <div className ="img_box relat">
                                                <div className="ribbon19-content">
                                                <span className="ribbon19">PREMIUM</span>
                                                </div>
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl_jobs2">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p> {replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                            {data.view_count ? 
                                            <div className='smallheart'>
                                                <img src="/assets/img/smallHeart.svg" alt="" /> 
                                                <p>{data.view_count}</p>
                                            </div> : <></>}
                                            
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            }
                            return <></>
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                        <span className="spinner"></span>
                      </div>
                        }
                    <a href="/jobs/recommended">すべて見る</a>
                </div> 
                <div>
                    <h2 className="ttl type1">
                        <img src="./assets/img/top/pickup.svg" alt=""></img>
                        <span>ピックアップ求人</span>
                    </h2>
                        {recruitmentsPickUp.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            keyboard={{
                                enabled: true
                            }}
                            loop
                            >
                                {recruitmentsPickUp.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li className='borderedBox'>
                                        <a href={'/recruitments/'+ data.id }>
                                            {/* <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div> */}
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p> {replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                        <span className="spinner"></span>
                      </div>
                        }
                    {/* <a href="/jobs/recommended">すべて見る</a> */}
                    <a href="/recruitments/?page=1&employment_status_ids[]=1&employment_status_ids[]=2">すべて見る</a>
                </div>
                <div>
                    <h2 className ="ttl type1">
                        <img src="/assets/img/top/physical_disability.svg" alt=""></img>
                        <span>身体的</span>なことへの配慮がある仕事
                    </h2>
                    {recruitments1.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments1.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate" >{data.new_graduate}</p>
                                            <p> {replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=1">すべて見る</a>
                </div>
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/heart.svg" alt=""></img>
                        <span>精神的</span>なことへの配慮がある仕事
                    </h2>
                    {recruitments2.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments2.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=2">すべて見る</a>
                </div>
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/star.svg" alt=""></img>
                        <span>発達特性</span>への配慮がある仕事
                    </h2>
                    {recruitments3.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments3.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=3">すべて見る</a>
                </div>
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/rainbow.svg" alt=""></img>
                        <span>知的障がい</span>への配慮がある仕事
                    </h2>
                    {recruitments4.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments4.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id}>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=4">すべて見る</a>
                </div>
                <div>
                    <h2 className="ttl type1">
                        <img src="/assets/img/top/pin.svg" alt=""></img>
                        <span>難病・その他特性</span>への配慮がある仕事
                    </h2>
                    {recruitments5.length !== 0 ? 
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={20}
                            navigation
                            slidesPerView={windowSize.width <= 768 ? 2 : 4}
                            loop
                            >
                                {recruitments5.map((data: any) => {
                                return (
                                <SwiperSlide key={data.id} className ="slider">
                                    <li>
                                        <a href={'/recruitments/'+ data.id }>
                                            <div className ="img_box">
                                                <img src= {data.thumbnail} ></img>
                                            </div>
                                            <h3  className = "ttl">{data.job_title} / {data.company_name}</h3>
                                            <p>{data.salary_form} {data.lower_monthly_basic_salary.toLocaleString()} 〜{data.upper_monthly_basic_salary.toLocaleString()} 円 </p>
                                            <p className = "category">{data.employment_status}</p>
                                            <p className = "new_graduate">{data.new_graduate}</p>
                                            <p>{replacedContent(data.work_location) ? <div dangerouslySetInnerHTML={{ __html: replacedContent(data.work_location) }} /> : ''}</p>
                                            {data.already_applied ? <p className="done" >エントリー済み</p>: <></>}
                                        </a>
                                    </li>
                                </SwiperSlide>
                                );
                            })}
                        </Swiper>: 
                        <div className="load-spinner">
                          <span className="spinner"></span>
                        </div>
                        }
                    <a href="/recruitments/?page=1&handicap_type_ids[]=5">すべて見る</a>
                </div>
                </>
            }
            </section>
            {/* <Column01 /> */}
            {!isLogin ? (
                <div className="btn_box">
                    <p>登録は<span>1分</span>で完了</p>
                    <a href="/login/"><img src="/assets/img/header_icon03.svg" alt=""/>ログイン／登録</a>
                    <a href="/recruiter_lp/">採用担当者様はこちら</a>
                </div>
            ): null}
            {/* <Column /> */}
            {isLogin ? (
                <section className="resume wrapper">
                    <div className="ttl type2">
                        <p><img src="/assets/img/top/ttl_number03.svg" alt="For You 03"/></p>
                        <h2>あなたの履歴書<span>登録後、マイページから自分の情報を入力すると自動で履歴書が作られます！</span></h2>
                    </div>
                    <a  href="/mypage/profile/"><img src="/assets/img/top/resume_btn.svg" alt="履歴書(PDF)をダウンロード"/></a>
                </section>
            ) : null}
        </main>
    );
  }
  
  export default Indexs;
